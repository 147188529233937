.popup-dialog {
    padding: 20px;
    max-width: 600px;
}

.popup-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
}

.close-button {
    color: #ff5e62;
}

.mode-select-container {
    margin: 15px 0px 15px 0px;
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 0;
    max-height: 400px;
    overflow-y: auto;
}

.day-button {
    min-width: 100px;
    margin: 5px;
}

.pagination-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.page-info {
    font-size: 1rem;
    margin: 0 10px;
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.win-counter {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    padding-right:15px;
}

.win-counter .win-count {
    margin-left: 5px;
}